import React from "react";

function Courses() {
  return (
    <>
      <main id="main " className="do">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container" data-aos="fade-up">
            <h1 className="comming">COMING SOON...</h1>
          </div>
        </section>
      </main>
    </>
  );
}

export default Courses;
