const Counties = [
  { name: "Pick From Shop", code: "00" },
  { name: "Baringo", code: "01" },
  { name: "Bomet", code: "02" },
  { name: "Bungoma", code: "03" },
  { name: "Busia", code: "04" },
  { name: "Elgeyo-Marakwet", code: "05" },
  { name: "Embu", code: "06" },
  { name: "Garissa", code: "07" },
  { name: "Homa Bay", code: "08" },
  { name: "Isiolo", code: "09" },
  { name: "Kajiado", code: "10" },
  { name: "Kakamega", code: "11" },
  { name: "Kericho", code: "12" },
  { name: "Kiambu", code: "13" },
  { name: "Kilifi", code: "14" },
  { name: "Kirinyaga", code: "15" },
  { name: "Kisii", code: "16" },
  { name: "Kisumu", code: "17" },
  { name: "Kitui", code: "18" },
  { name: "Kwale", code: "19" },
  { name: "Laikipia", code: "20" },
  { name: "Lamu", code: "21" },
  { name: "Machakos", code: "22" },
  { name: "Makueni", code: "23" },
  { name: "Mandera", code: "24" },
  { name: "Marsabit", code: "25" },
  { name: "Meru", code: "26" },
  { name: "Migori", code: "27" },
  { name: "Mombasa", code: "28" },
  { name: "Murang'a", code: "29" },
  { name: "Nakuru", code: "31" },
  { name: "Nandi", code: "32" },
  { name: "Narok", code: "33" },
  { name: "Nyamira", code: "34" },
  { name: "Nairobi", code: "30" },
  { name: "Nyandarua", code: "35" },
  { name: "Nyeri", code: "36" },
  { name: "Samburu", code: "37" },
  { name: "Siaya", code: "38" },
  { name: "Taita Taveta", code: "39" },
  { name: "Tana River", code: "40" },
  { name: "Tharaka-Nithi", code: "41" },
  { name: "Trans Nzoia", code: "42" },
  { name: "Turkana", code: "43" },
  { name: "Uasin Gishu", code: "44" },
  { name: "Vihiga", code: "45" },
  { name: "Wajir", code: "46" },
  { name: "West Pokot", code: "47" },
];

const locations = [
  { id: 193, name: " " },
  { id: 1, name: "Banana Hill" },
  { id: 2, name: "Bibirioni" },
  { id: 3, name: "Cianda" },
  { id: 4, name: "Dagoretti" },
  { id: 5, name: "Delta" },
  { id: 6, name: "Gachagi" },
  { id: 7, name: "Gachika" },
  { id: 8, name: "Gachororo" },
  { id: 9, name: "Gachie" },
  { id: 10, name: "Gaitu" },
  { id: 11, name: "Gathanga" },
  { id: 12, name: "Gathiruini" },
  { id: 13, name: "Gatong'ora" },
  { id: 14, name: "Gatukuyu" },
  { id: 15, name: "Gatundu" },
  { id: 16, name: "Gatura" },
  { id: 17, name: "Gichuru" },
  { id: 18, name: "Gikambura" },
  { id: 19, name: "Gikoe" },
  { id: 20, name: "Gikono" },
  { id: 21, name: "Githunguri" },
  { id: 22, name: "Githurai" },
  { id: 23, name: "Gituamba" },
  { id: 24, name: "Gitweko" },
  { id: 25, name: "Ichaweri" },
  { id: 26, name: "Ikinu" },
  { id: 27, name: "Imara Daima" },
  { id: 28, name: "Juja" },
  { id: 29, name: "Kahuho" },
  { id: 30, name: "Kahawa" },
  { id: 31, name: "Kahawa Sukari" },
  { id: 32, name: "Kahawa Wendani" },
  { id: 33, name: "Kabete" },
  { id: 34, name: "Kabuku" },
  { id: 35, name: "Kambaa" },
  { id: 36, name: "Kamiti" },
  { id: 37, name: "Kamwangi" },
  { id: 38, name: "Kangoya" },
  { id: 39, name: "Kangundo" },
  { id: 40, name: "Kanunga" },
  { id: 41, name: "Karai" },
  { id: 42, name: "Karatu" },
  { id: 43, name: "Karuri" },
  { id: 44, name: "Kiamaina" },
  { id: 45, name: "Kiamariga" },
  { id: 46, name: "Kiamumbi" },
  { id: 47, name: "Kiamwangi" },
  { id: 48, name: "Kiandutu" },
  { id: 49, name: "Kiang'ombe" },
  { id: 50, name: "Kianjau" },
  { id: 51, name: "Kibichoi" },
  { id: 52, name: "Kibiko" },
  { id: 53, name: "Kibunja" },
  { id: 54, name: "Kigumo" },
  { id: 55, name: "Kigumo Gakuyu" },
  { id: 56, name: "Kigwe" },
  { id: 57, name: "Kihara" },
  { id: 58, name: "Kihingo" },
  { id: 59, name: "Kikuyu" },
  { id: 60, name: "Kilimambogo" },
  { id: 61, name: "Kinale" },
  { id: 62, name: "Kindaruma" },
  { id: 63, name: "Kinoo" },
  { id: 64, name: "Kinungi" },
  { id: 65, name: "Kiratina" },
  { id: 66, name: "Kiria-ini" },
  { id: 67, name: "Kiriita" },
  { id: 68, name: "Kiriko" },
  { id: 69, name: "Kirigiti" },
  { id: 70, name: "Kiriti Murungai" },
  { id: 71, name: "Kiwara" },
  { id: 72, name: "Kongo" },
  { id: 73, name: "Kwa Maiko" },
  { id: 74, name: "Kwamaiko" },
  { id: 75, name: "Kyuna" },
  { id: 76, name: "Lari" },
  { id: 77, name: "Limuru" },
  { id: 78, name: "Maai Mahiu" },
  { id: 79, name: "Magumu" },
  { id: 80, name: "Makongeni" },
  { id: 81, name: "Mang'u" },
  { id: 82, name: "Mathare" },
  { id: 83, name: "Mawingu" },
  { id: 84, name: "Mbiri" },
  { id: 85, name: "Miguta" },
  { id: 86, name: "Mihango" },
  { id: 87, name: "Mikinduri" },
  { id: 88, name: "Mwiki" },
  { id: 89, name: "Nachu" },
  { id: 90, name: "Ndeiya" },
  { id: 91, name: "Ndumberi" },
  { id: 92, name: "Ngara" },
  { id: 93, name: "Ngenda" },
  { id: 94, name: "Ngewa" },
  { id: 95, name: "Ngoliba" },
  { id: 96, name: "Ngong" },
  { id: 97, name: "Ngecha" },
  { id: 98, name: "Ngegu" },
  { id: 99, name: "Nyadhuna" },
  { id: 100, name: "Nyathuna" },
  { id: 101, name: "Nyokabi" },
  { id: 102, name: "Pongola" },
  { id: 103, name: "Rironi" },
  { id: 104, name: "Ruaka" },
  { id: 105, name: "Rugongo" },
  { id: 106, name: "Ruiru" },
  { id: 107, name: "Runyenjes" },
  { id: 108, name: "Thathi" },
  { id: 109, name: "Thika" },
  { id: 110, name: "Tigoni" },
  { id: 111, name: "Ting'ang'a" },
  { id: 112, name: "Uplands" },
  { id: 113, name: "Wangige" },
  { id: 114, name: "Wanguru" },
  { id: 115, name: "Airbase" },
  { id: 116, name: "Bahati" },
  { id: 117, name: "Baba Dogo" },
  { id: 118, name: "Bellevue" },
  { id: 119, name: "Buru Buru" },
  { id: 120, name: "City Square" },
  { id: 121, name: "Civil Servants" },
  { id: 122, name: "Dandora" },
  { id: 123, name: "Donholm" },
  { id: 124, name: "Eastleigh" },
  { id: 125, name: "EMBAKASI" },
  { id: 126, name: "Fedha" },
  { id: 127, name: "Gikomba" },
  { id: 128, name: "Githurai" },
  { id: 129, name: "Highridge" },
  { id: 130, name: "Hospital" },
  { id: 131, name: "Huruma" },
  { id: 132, name: "Imara Daima" },
  { id: 133, name: "Industrial Area" },
  { id: 134, name: "Jericho" },
  { id: 135, name: "Jogoo Road" },
  { id: 136, name: "Kabiria" },
  { id: 137, name: "Kahawa West" },
  { id: 138, name: "Kaloleni" },
  { id: 139, name: "Kangemi" },
  { id: 140, name: "Karen" },
  { id: 141, name: "Karura" },
  { id: 142, name: "Kawangware" },
  { id: 143, name: "Kibera" },
  { id: 144, name: "Kileleshwa" },
  { id: 145, name: "Kilimani" },
  { id: 146, name: "Kimathi" },
  { id: 147, name: "Kitisuru" },
  { id: 148, name: "KNH" },
  { id: 149, name: "Koibatek" },
  { id: 150, name: "Komarock" },
  { id: 151, name: "KU" },
  { id: 152, name: "Kware" },
  { id: 153, name: "Kyangombe" },
  { id: 154, name: "Lang'ata" },
  { id: 155, name: "Lavington" },
  { id: 156, name: "Loresho" },
  { id: 157, name: "Lucky Summer" },
  { id: 158, name: "Madaraka" },
  { id: 159, name: "Majengo" },
  { id: 160, name: "Makadara" },
  { id: 161, name: "Makongeni" },
  { id: 162, name: "Mathare North" },
  { id: 163, name: "Mathare Valley" },
  { id: 164, name: "Maziwa" },
  { id: 165, name: "Mbotela" },
  { id: 166, name: "Mwiki" },
  { id: 167, name: "Nairobi Central" },
  { id: 168, name: "Nairobi West" },
  { id: 169, name: "Ngara" },
  { id: 170, name: "Njiru" },
  { id: 171, name: "Ongata Rongai" },
  { id: 172, name: "Pangani" },
  { id: 173, name: "Parklands" },
  { id: 174, name: "Pipeline" },
  { id: 175, name: "Pumwani" },
  { id: 176, name: "Riruta" },
  { id: 177, name: "Ruaraka" },
  { id: 178, name: "Saika" },
  { id: 179, name: "South B" },
  { id: 180, name: "South C" },
  { id: 181, name: "Soweto" },
  { id: 182, name: "Starehe" },
  { id: 183, name: "Sunton" },
  { id: 184, name: "Tassia" },
  { id: 185, name: "Thome" },
  { id: 186, name: "Umoja" },
  { id: 187, name: "Upper Hill" },
  { id: 188, name: "Utawala" },
  { id: 189, name: "Valley Road" },
  { id: 190, name: "Viwandani" },
  { id: 191, name: "Westlands" },
  { id: 192, name: "Woodley" },
];

const subCounties = [
  { id: 1, code: "00", name: "Nairobi CBD Shop,Magic bussiness center" },
  { id: 2, code: "00", name: "Kamutiini Shop,Lower kabete Rd" },
  { id: 1, code: "01", name: "Baringo Central" },
  { id: 2, code: "01", name: "Baringo North" },
  { id: 3, code: "01", name: "Baringo South" },
  { id: 4, code: "01", name: "Eldama Ravine" },
  { id: 5, code: "01", name: "Mogotio" },
  { id: 6, code: "01", name: "Tiaty" },
  { id: 7, code: "02", name: "Bomet Central" },
  { id: 8, code: "02", name: "Bomet East" },
  { id: 9, code: "02", name: "Chepalungu" },
  { id: 10, code: "02", name: "Konoin" },
  { id: 11, code: "02", name: "Sotik" },
  { id: 12, code: "03", name: "Bumula" },
  { id: 13, code: "03", name: "Kabuchai" },
  { id: 14, code: "03", name: "Kanduyi" },
  { id: 15, code: "03", name: "Kimilili" },
  { id: 16, code: "03", name: "Mt. Elgon" },
  { id: 17, code: "03", name: "Sirisia" },
  { id: 18, code: "03", name: "Tongaren" },
  { id: 19, code: "03", name: "Webuye East" },
  { id: 20, code: "03", name: "Webuye West" },
  { id: 21, code: "04", name: "Butula" },
  { id: 22, code: "04", name: "Funyula" },
  { id: 23, code: "04", name: "Budalangi" },
  { id: 24, code: "04", name: "Nambale" },
  { id: 25, code: "04", name: "Matayos" },
  { id: 26, code: "05", name: "Keiyo North" },
  { id: 27, code: "05", name: "Keiyo South" },
  { id: 28, code: "05", name: "Marakwet East" },
  { id: 29, code: "05", name: "Marakwet West" },
  { id: 30, code: "06", name: "Manyatta" },
  { id: 31, code: "06", name: "Mbeere North" },
  { id: 32, code: "06", name: "Mbeere South" },
  { id: 33, code: "07", name: "Dadaab" },
  { id: 34, code: "07", name: "Fafi" },
  { id: 35, code: "07", name: "Garissa Township" },
  { id: 36, code: "07", name: "Ijara" },
  { id: 37, code: "07", name: "Lagdera" },
  { id: 38, code: "08", name: "Homa Bay Town" },
  { id: 40, code: "08", name: "Kabondo Kasipul" },
  { id: 41, code: "08", name: "Karachuonyo" },
  { id: 42, code: "08", name: "Kasipul" },
  { id: 43, code: "08", name: "Mbita" },
  { id: 44, code: "08", name: "Ndhiwa" },
  { id: 45, code: "08", name: "Rangwe" },
  { id: 46, code: "08", name: "Suba" },
  { id: 47, code: "09", name: "Central" },
  { id: 48, code: "09", name: "Garbatulla" },
  { id: 49, code: "09", name: "Isiolo North" },
  { id: 50, code: "09", name: "Isiolo South" },
  { id: 51, code: "10", name: "Isinya" },
  { id: 52, code: "10", name: "Kajiado Central" },
  { id: 53, code: "10", name: "Kajiado East" },
  { id: 54, code: "10", name: "Kajiado North" },
  { id: 55, code: "10", name: "Kajiado West" },
  { id: 56, code: "10", name: "Loitokitok" },
  { id: 57, code: "10", name: "Mashuuru" },
  { id: "21", code: "11", name: "Butere" },
  { id: "22", code: "11", name: "Butsotso East" },
  { id: "23", code: "11", name: "Butsotso South" },
  { id: "24", code: "11", name: "Kabras East" },
  { id: "25", code: "11", name: "Kabras West" },
  { id: "26", code: "11", name: "Lugari" },
  { id: "27", code: "11", name: "Lukuyani" },
  { id: "28", code: "11", name: "Lumakanda" },
  { id: "29", code: "11", name: "Likuyani" },
  { id: "30", code: "11", name: "Matete" },
  { id: "31", code: "11", name: "Matungu" },
  { id: "32", code: "11", name: "Mumias East" },
  { id: "33", code: "11", name: "Mumias West" },
  { id: "34", code: "11", name: "Navakholo" },
  { id: "22", code: "12", name: "Ainamoi" },
  { id: "23", code: "12", name: "Belgut" },
  { id: "24", code: "12", name: "Kipkelion East" },
  { id: "25", code: "12", name: "Kipkelion West" },
  { id: "26", code: "12", name: "Soin/Sigowet" },
  { id: "27", code: "13", name: "Gatundu North" },
  { id: "28", code: "13", name: "Gatundu South" },
  { id: "29", code: "13", name: "Githunguri" },
  { id: "30", code: "13", name: "Juja" },
  { id: "31", code: "13", name: "Kabete" },
  { id: "32", code: "13", name: "Kiambaa" },
  { id: "33", code: "13", name: "Kiambu" },
  { id: "34", code: "13", name: "Kikuyu" },
  { id: "35", code: "13", name: "Limuru" },
  { id: "36", code: "13", name: "Lari" },
  { id: "37", code: "13", name: "Ruiru" },
  { id: "38", code: "13", name: "Thika Town" },
  { id: "39", code: "14", name: "Ganze" },
  { id: "40", code: "14", name: "Kaloleni" },
  { id: "41", code: "14", name: "Kilifi North" },
  { id: "42", code: "14", name: "Kilifi South" },
  { id: "43", code: "14", name: "Magarini" },
  { id: "44", code: "14", name: "Malindi" },
  { id: "45", code: "14", name: "Rabai" },
  { id: 1501, code: "15", name: "Kirinyaga Central" },
  { id: 1502, code: "15", name: "Kirinyaga East" },
  { id: 1503, code: "15", name: "Kirinyaga West" },
  { id: 1601, code: "16", name: "Bonchari" },
  { id: 1602, code: "16", name: "South Mugirango" },
  { id: 1603, code: "16", name: "Bomachoge Borabu" },
  { id: 1604, code: "16", name: "Bomachoge Chache" },
  { id: 1605, code: "16", name: "Bobasi" },
  { id: 1606, code: "16", name: "Nyamira North" },
  { id: 1607, code: "16", name: "Nyamira South" },
  { id: 1608, code: "16", name: "Kitutu Chache North" },
  { id: 1609, code: "16", name: "Kitutu Chache South" },
  { id: 1701, code: "17", name: "Kisumu East" },
  { id: 1702, code: "17", name: "Kisumu Central" },
  { id: 1703, code: "17", name: "Kisumu West" },
  { id: 1704, code: "17", name: "Seme" },
  { id: 1705, code: "17", name: "Nyando" },
  { id: 1801, code: "18", name: "Kitui Central" },
  { id: 1802, code: "18", name: "Kitui Rural" },
  { id: 1803, code: "18", name: "Kitui West" },
  { id: 1804, code: "18", name: "Kitui South" },
  { id: 1805, code: "18", name: "Kitui East" },
  { id: 1806, code: "18", name: "Mwingi West" },
  { id: 1807, code: "18", name: "Mwingi Central" },
  { id: 1808, code: "18", name: "Mwingi North" },
  { id: 1809, code: "18", name: "Mutitu" },
  { id: 1810, code: "18", name: "Kyuso" },
  { id: 1811, code: "18", name: "Lower Yatta" },
  { id: 1812, code: "18", name: "Upper Yatta" },
  { id: 1901, code: "19", name: "Kinango" },
  { id: 1902, code: "19", name: "Lungalunga" },
  { id: 1903, code: "19", name: "Msambweni" },
  { id: 1904, code: "19", name: "Matuga" },
  { id: 2001, code: "20", name: "Laikipia East" },
  { id: 2002, code: "20", name: "Laikipia North" },
  { id: 2003, code: "20", name: "Laikipia West" },
  { id: 2101, code: "21", name: "Lamu East" },
  { id: 2102, code: "21", name: "Lamu West" },
  { id: 2201, code: "22", name: "Masinga" },
  { id: 2202, code: "22", name: "Yatta" },
  { id: 2203, code: "22", name: "Kangundo" },
  { id: 2204, code: "22", name: "Matungulu" },
  { id: 2205, code: "22", name: "Kathiani" },
  { id: 2206, code: "22", name: "Mavoko" },
  { id: 2207, code: "22", name: "Machakos Town" },
  { id: 2301, code: "23", name: "Kilome" },
  { id: 2302, code: "23", name: "Kaiti" },
  { id: 2303, code: "23", name: "Mbooni" },
  { id: 2304, code: "23", name: "Makueni" },
  { id: 2305, code: "23", name: "Kibwezi West" },
  { id: 2306, code: "23", name: "Kibwezi East" },
  { id: 2401, code: "24", name: "Banisa" },
  { id: 2402, code: "24", name: "Mandera West" },
  { id: 2403, code: "24", name: "Mandera North" },
  { id: 2404, code: "24", name: "Mandera South" },
  { id: 2405, code: "24", name: "Mandera East" },
  { name: "Chalbi", code: "25", id: "251" },
  { name: "Laisamis", code: "25", id: "252" },
  { name: "Loiyangalani", code: "25", id: "253" },
  { name: "Moyale", code: "25", id: "254" },
  { name: "North Horr", code: "25", id: "255" },
  { name: "Buuri", code: "26" },
  { id: 2402, name: "Igembe Central", code: "26" },
  { id: 2402, name: "Igembe North", code: "26" },
  { id: 2402, name: "Igembe South", code: "26" },
  { id: 2402, name: "Imenti Central", code: "26" },
  { id: 2402, name: "Imenti North", code: "26" },
  { id: 2402, name: "Imenti South", code: "26" },
  { id: 2402, name: "Meru Central", code: "26" },
  { id: 2402, name: "Meru North", code: "26" },
  { id: 2402, name: "Meru South", code: "26" },
  { id: 2402, name: "Tigania Central", code: "26" },
  { id: 2402, name: "Tigania East", code: "26" },
  { id: 2402, name: "Tigania West", code: "26" },
  { name: "Awendo", code: "27" },
  { name: "Kuria East", code: "27" },
  { name: "Kuria West", code: "27" },
  { name: "Mabera", code: "27" },
  { name: "Nyatike", code: "27" },
  { name: "Rongo", code: "27" },
  { name: "Suna East", code: "27" },
  { name: "Suna West", code: "27" },
  { name: "Uriri", code: "27" },
  { name: "Changamwe", code: "28" },
  { name: "Jomvu", code: "28" },
  { name: "Kisauni", code: "28" },
  { name: "Likoni", code: "28" },
  { name: "Mvita", code: "28" },
  { name: "Nyali", code: "28" },
  { name: "Gatanga", code: "29" },
  { name: "Kahuro", code: "29" },
  { name: "Kandara", code: "29" },
  { name: "Kangema", code: "29" },
  { name: "Kigumo", code: "29" },
  { name: "Kirinyaga", code: "29" },
  { name: "Maragua", code: "29" },
  { name: "Gilgil", code: "31" },
  { name: "Naivasha", code: "31" },
  { name: "Nakuru Town East", code: "31" },
  { name: "Nakuru Town West", code: "31" },
  { name: "Rongai", code: "31" },
  { name: "Subukia", code: "31" },
  { name: "Chesumei", code: "32" },
  { name: "Emgwen", code: "32" },
  { name: "Mosop", code: "32" },
  { name: "Nandi Central", code: "32" },
  { name: "Nandi East", code: "32" },
  { name: "Nandi Hills", code: "32" },
  { name: "Tinderet", code: "32" },
  { name: "Kilgoris", code: "33" },
  { name: "Narok East", code: "33" },
  { name: "Narok North", code: "33" },
  { name: "Narok South", code: "33" },
  { name: "Transmara East", code: "33" },
  { name: "Transmara West", code: "33" },
  { name: "Borabu", code: "34" },
  { name: "Manga", code: "34" },
  { name: "Masaba North", code: "34" },
  { name: "Masaba South", code: "34" },
  { name: "Nyamira North", code: "34" },
  { name: "Nyamira South", code: "34" },
  { name: "Westlands", code: "30" },
  { name: "Dagoretti North", code: "30" },
  { name: "Dagoretti South", code: "30" },
  { name: "Langata", code: "30" },
  { name: "Kibra", code: "30" },
  { name: "Roysambu", code: "30" },
  { name: "Kasarani", code: "30" },
  { name: "Ruaraka", code: "30" },
  { name: "Embakasi South", code: "30" },
  { name: "Embakasi North", code: "30" },
  { name: "Embakasi Central", code: "30" },
  { name: "Embakasi East", code: "30" },
  { name: "Embakasi West", code: "30" },
  { name: "Makadara", code: "30" },
  { name: "Kamukunji", code: "30" },
  { name: "Starehe", code: "30" },
  { name: "Kinangop", code: "35" },
  { name: "Kipipiri", code: "35" },
  { name: "Ol Joro Orok", code: "35" },
  { name: "Ndaragwa", code: "35" },
  { name: "Mirangine", code: "35" },
  { name: "Tetu", code: "36" },
  { name: "Kieni East", code: "36" },
  { name: "Kieni West", code: "36" },
  { name: "Mathira East", code: "36" },
  { name: "Mathira West", code: "36" },
  { name: "Othaya", code: "36" },
  { name: "Mukurweini", code: "36" },
  { name: "Nyeri Central", code: "36" },
  { name: "Samburu West", code: "37" },
  { name: "Samburu North", code: "37" },
  { name: "Samburu East", code: "37" },
  { name: "Alego Usonga", code: "38" },
  { name: "Gem", code: "38" },
  { name: "Bondo", code: "38" },
  { name: "Rarieda", code: "38" },
  { name: "Ugenya", code: "38" },
  { name: "Ugunja", code: "38" },
  { name: "Taveta", code: "39" },
  { name: "Wundanyi", code: "39" },
  { name: "Mwatate", code: "39" },
  { name: "Garsen", code: "40" },
  { name: "Galole", code: "40" },
  { name: "Bura", code: "40" },
  { name: "Maara", code: "41" },
  { name: "Chuka", code: "41" },
  { name: "Tharaka", code: "41" },
  { name: "Kwanza", code: "42" },
  { name: "Endebess", code: "42" },
  { name: "Saboti", code: "42" },
  { name: "Kiminini", code: "42" },
  { name: "Central Turkana", code: "43" },
  { name: "Kibish", code: "43" },
  { name: "Loima", code: "43" },
  { name: "Turkana East", code: "43" },
  { name: "Turkana North", code: "43" },
  { name: "Turkana South", code: "43" },
  { name: "Ainabkoi", code: "44" },
  { name: "Kapseret", code: "44" },
  { name: "Kesses", code: "44" },
  { name: "Moiben", code: "44" },
  { name: "Soy", code: "44" },
  { name: "Turbo", code: "44" },
  { name: "Emuhaya", code: "45" },
  { name: "Hamisi", code: "45" },
  { name: "Luanda", code: "45" },
  { name: "Sabatia", code: "45" },
  { name: "Vihiga", code: "45" },
  { name: "Eldas", code: "46" },
  { name: "Tarbaj", code: "46" },
  { name: "Wajir East", code: "46" },
  { name: "Wajir North", code: "46" },
  { name: "Wajir South", code: "46" },
  { name: "Wajir West", code: "46" },
  { name: "Kipkomo", code: "47" },
  { name: "Sigor", code: "47" },
  { name: "Kacheliba", code: "47" },
  { name: "Pokot Central", code: "47" },
  { name: "Pokot South", code: "47" },
];

const month = [
  { id: 1, name: "JAN" },
  { id: 2, name: "FEB" },
  { id: 3, name: "MAR" },
  { id: 4, name: "APR" },
  { id: 5, name: "MAY" },
  { id: 6, name: "JUNE" },
  { id: 7, name: "JULY" },
  { id: 8, name: "AUG" },
  { id: 9, name: "SEPT" },
  { id: 10, name: "OCT" },
  { id: 11, name: "NOV" },
  { id: 12, name: "DEC" },
];

export { subCounties, locations, Counties, month };
